/** =================== Avenir =================== **/

/** Avenir Roman **/
@font-face {
	font-family: "Avenir Roman";
	src: url("Avenir-Roman.woff");
}

/** Hanson Bold **/
@font-face {
	font-family: "Hanson Bold";
	font-weight: 700;
	font-style: normal;
	src: url("Hanson-Bold-700.woff");
}
