@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap);
/** =================== Avenir =================== **/

/** Avenir Roman **/
@font-face {
	font-family: "Avenir Roman";
	src: url(/static/media/Avenir-Roman.c383ea62.woff);
}

/** Hanson Bold **/
@font-face {
	font-family: "Hanson Bold";
	font-weight: 700;
	font-style: normal;
	src: url(/static/media/Hanson-Bold-700.a59fca78.woff);
}

/* public\assets\fonts\Avenir\AvenirLTStd-Book.otf */
.container {
	max-width: 1920px !important;
	width: 90% !important;
	margin-left: auto;
	margin-right: auto;
}

*,
*::before,
*::after {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

:root {
	/* 1rem= 10px, 10px/16px = 62.5% */
	font-size: 62.5%;
	/* font-family: "Poppins", sans-serif !important; */
}
/* 1200px/16 = 75em */
@media screen and (max-width: 992px) {
	:root {
		/* want 1rem = 9px; 9/16 = 0.5625 = 56.25%; */
		font-size: 56.25%;
	}
}
/* 900px/16 = 56.25em */
/* @media screen and (max-width: 900px) {
  :root {
    want 1rem = 8px; 8/16 = 0.5 = 50%
    font-size: 50%;
  }
} */
@media screen and (max-width: 768px) {
	:root {
		/* want 1rem = 8px; 8/16 = 0.5 = 50% */
		font-size: 50%;
	}
}

@media screen and (max-width: 576px) {
	:root {
		/* want 1rem = 7px; 7/16 = 0.4375 = 43.75% */
		font-size: 50%;
		/* font-size: 10%; */
	}
}

/* 1400px/16 = 87.5em */
/* @media screen and (min-width: 1200px) {
  :root {
    1rem = 12px;  12/16 =0.75 = 75% 
    1rem= 10px, 10px/16px = 62.5%
    font-size: 62.5%;
  }
} */

/* 1800px/16 = 37.5em */
@media screen and (min-width: 1441px) {
	:root {
		/* 1rem = 14px;  14/16 =0.875 = 87.5% */
		/* 1rem = 12px;  12/16 =0.75 = 75%  */
		font-size: 75%;
	}
}

